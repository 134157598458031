$(document).ready(function() {

    // $('.owl-carousel-galerija').owlCarousel({
    //     margin: 10,
    //     autoHeight: true,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },
    //         600: {
    //             items: 1
    //         },
    //         1000: {
    //             items: 1
    //         }
    //     }
    // });

    let owl = $('.owl-carousel-two');

    setTimeout(function() {
        if (!$("input[name='boja']:checked").val()) {
            $('.colors').find('input[name=boja]:eq(0)').attr('checked', 'checked');
        }
        $("input[type='radio'][name='boja']:checked").trigger('change');
    }, 0.1);

    var choosen_color = null;
    var choosen_size = null;
    var product = null;
    var buy_id = null;

    var productdata = JSON.parse($('.json').text());


    $("input[type='radio'][name='boja']").on('change', function() {
        product = productdata.products[$(this).val()];
        product = product[Object.keys(product)[0]][0];


        choosen_color = $(this).val();
        choosen_size = null;
        setproductdata(product, choosen_color, choosen_size, "color");

        // set color hidden input
        $('#request_color').val(choosen_color);
        // load images for preview
        if (product.images.length < 4) {
            $('.thumbnails').thumbnailsGal();
        } else {
            owl.thumbnailsGal({
                renderAfter: true,
                selector: '.owl-carousel-two'
            });
        }
        $('.zoom').zoom();


        //end load images for preview

        return false;
    });


    function initDataForColor() {
        // console.log($('input[name="boja"]:checked').val());
        product = productdata.products[$('input[name="boja"]:eq(0)').val()];
        product = product[Object.keys(product)[0]][0];
        // console.log(productdata);
        choosen_color = $('input[name="boja"]:checked').val();
        // console.log(choosen_color);
        choosen_size = null;

        setproductdata(product, choosen_color, choosen_size, "color");


        return false;
    }

    function setImg() {
        // load images for preview
        $('.product-img-list').thumbnailsGal();
        $('.zoom').zoom();
        console.log('treba da radi aman');
        //end load images for preview
    }

    function setproductdata(product, choosen_color = null, choosen_size = null, type = null) {

        if (type != "first") {
            $(".thumbnails").text('');
            let owl = $('.owl-carousel-two');

            $('.owl-carousel-two .item').each((index, value) => {
                owl.trigger('remove.owl.carousel', index);
            })

            $.each(product.images, function(key, value) {
                if (key == 0) {
                    $('.image-product').attr('src', value);
                }
                item = '<a href="' + value + '" class="product-margin"> <img src="' + value + '" alt="Thumbnail" class="thumb"> </a>';

                owl.owlCarousel('add', '<div class="item"> ' + item + ' </div>');

            });
            owl.owlCarousel('update'); //Updatuje carousel
            owl.thumbnailsGal({
                renderAfter: true,
                selector: '.owl-carousel-two'
            });
        }

        if (type != "first") {
            $(".thumbnails").text('');
            let owl = $('.owl-carousel-galerija');

            $('.owl-carousel-galerija .item').each((index, value) => {
                owl.trigger('remove.owl.carousel', index);
            })

            $.each(product.images, function(key, value) {
                if (key == 0) {
                    $('.image-product').attr('src', value);
                }
                item = '<span "><img src="' + value + '" alt="MODAL" ></zoom>';

                owl.owlCarousel('add', '<div class="item"> ' + item + ' </div>');

            });
            owl.owlCarousel('update'); //Updatuje carousel
            owl.thumbnailsGal({
                renderAfter: true,
                selector: '.owl-carousel-galerija'
            });
        }


        //sizes
        //var sizes = Object.keys(eval('productdata.products["' + choosen_color + '"]')).filter(function(val) { return val!='' });
        //console.log();


        if (type != "size") {

            console.log('OVO JE CON LOG',type);

            //$('.velicine').text('');
            $('.tabela-podaci tbody').text('');
            $('#accordion').text('');
            $('.tabela-podaci-h').show();
            let collapse_index = 1;
            let show = 'show';
            $.each(productdata.products[choosen_color], function(key, value) {
                let tdList;

                console.log(value);

                let zaglavlje_tabele = '<th class="d-xs-none">Šifra</th><th>Na lageru</th><th>U dolasku</th><th>Cena</th><th>Količina</th>';
                let sifra_velicine = value[0].real_id;
                tdList += '<td class="size d-xs-none">' + value[0].real_id + '</td>';
                tdList += '<td class="quantity quan' + key + '">' + value[0].quantity + '</td>';
                tdList += '<td><span class="arrivals arriv' + key + '">' + value[0].arrivals + '</span> <span>' + (value[0].all_details.Arrivals[0] ? '(' + value[0].all_details.Arrivals[0].Arrival + ')' : ' ') + '</span></td>';
                tdList += '<td class="price">' + Math.round(value[0].price * 100) / 100 + '&nbsp;EUR</td>';

                if (value[0].incart) {
                    if ($(window).width() < 768) {
                        tdList += '<td  colspan="3">Već je u listi.</td>';
                    } else {
                        tdList += '<td class="in-list">Već je u listi.</td>';
                        $('.dugme-za-upit').attr("disabled", "disabled");
                    }
                } else {
                    if ($(window).width() < 768) {
                        $('.kolicina').remove();
                        tdList += '<td class="quantity-buy">' +
                        '<div class="input-counter">'+
                        '<i class="icon-remove minus"></i>'+
                        '<input name="' + value[0].real_id + '" class=" form-control unesi-kolicinu" type="number" min="0" value="0"  >'+
                        '<i class="icon-add plus"></i>'+
                        '</div>'+
                        '</td>';
                        $('.dugme-za-upit').removeAttr("disabled");


                    }else {
                        tdList += '<td class="quantity-buy quan-buy' + key + '">'+
                        '<div class="input-counter">'+
                        '<i class="icon-remove minus"></i>'+
                        '<input name="' + value[0].real_id + '" class=" form-control unesi-kolicinu" type="number" min="0" value="0"  >'+
                        '<i class="icon-add plus"></i>'+
                        '</div>'+
                        '</td>';
                        $('.dugme-za-upit').removeAttr("disabled");
                    }





                }
                $('.tabela-podaci tbody').append('<tr>' + tdList + '</tr>');
                let tableDIV = '<table class="w-100">' +
                    '<tbody class="d-flex flex-row justify-content-between">' +
                    '<tr class="d-flex flex-column">' + zaglavlje_tabele + '</tr><tr class="d-flex flex-column pl-3">' + tdList + '</tr></tbody></table>';


                if (collapse_index != 1) {
                    show = '';
                }

                const parsetTableHTML = $(tableDIV)[1].outerHTML;
                $('#accordion').append('<div class="card mb-2">' +
                    '<div class="accordion-heading" id="heading' + collapse_index + '">' +
                    '<a class="btn btn-link" data-toggle="collapse" data-target="#collapse' + collapse_index + '" aria-expanded="true" aria-controls="collapse' + collapse_index + '">' + sifra_velicine + ' </a>' +
                    '</div>' +
                    '<div id="collapse' + collapse_index + '" class="collapse ' + show + '" aria-labelledby="heading' + collapse_index + '" data-parent="#accordion">' +
                    '<div class="card-body">' +
                    parsetTableHTML +
                    '</div></div></div>');

                collapse_index++;

                let color_table_head =$('.drop-size .show').parent();
                color_table_head.find('.accordion-heading').addClass('main-color-bg');
                // console.log('Oboji',color_table_head.find('.accordion-heading'));
                $('.drop-size .btn-link').on('click',function(){
                    $('.accordion-heading').removeClass('main-color-bg');
                    $(this).parent().toggleClass('main-color-bg');
                });

                // Kolicina 0 Start
                let product_cat = $('.product-cat-info').text();
                let kolicina = parseInt($('.quan' + key).text()) + parseInt($('.arriv' + key).text());
                let quantInput = $('.quan-buy' + key + ' input');

                // set max quantity
                quantInput.attr('max', kolicina);

                if (kolicina == 0 && product_cat != "Kalendari" && product_cat != "Kožna galanterija") {
                    $('.quantity-buy').append('Nije dostupan');
                    $('.unesi-kolicinu').addClass('d-none');
                    $('.input-counter').addClass('d-none');
                }
                // Kolicina 0 End
                if (value[0].quantity_query_on_request == 1 && (product_cat == 'Kalendari' || product_cat == 'Kožna galanterija')) {
                    // console.log(value[0].quantity_query_on_request);
                    console.log("Koza")
                    $('.table .quantity').empty().append('Na upit');
                    $('.table .arrivals').empty().append('Na upit');
                    $('.card-body .quantity').empty().append('Na upit');
                    $('.card-body .arrivals').empty().append('Na upit');

                    quantInput.attr('max', 999999);
                }


                /*$('<a/>', {
                    href: '#',
                    class: 'btn btn-default size',
                    text: value
                }).appendTo('.velicine');
                <tr>
                            <td class="size">50.030.90-S</td>
                            <td>700</td>
                            <td>3.50 EUR</td>
                            <td>
                                <input class="unesi-kolicinu" type="number" min="0" value="0">
                            </td>
                        </tr>
                */
            });
        }

        $('.minus').click(function () {
            var $input = $(this).parent().find('input');
            var count = parseInt($input.val()) - 1;
            count = count < 0 ? 0 : count;
            $input.val(count);
            $input.change();
            return false;
        });
        $('.plus').click(function () {
            var $input = $(this).parent().find('input');
            $input.val(parseInt($input.val()) + 1);
            $input.change();
            return false;
        });

    }
    initDataForColor();
    $('.list-pr').click(function(e) {
        e.preventDefault();
        let address = $(this).find('a').attr('href');
        console.log(address);
        $('.image-product').attr('src', address);
    });




});
