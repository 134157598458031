

    $('.tabela-podaci-h-mobile .upit-submit').click(function(){
        const tableRows = $('#accordion .card .collapse table tbody tr');

        check = true;
        tableRows.each(function(index, value) {
            const quantityValue = parseInt($(value).find('.quantity').text());
            const arrivalsValue = parseInt($(value).find('.arrivals').text());
            const inputValue = parseInt($(value).find('.unesi-kolicinu').val());
            const zbir = quantityValue + arrivalsValue;

            if(inputValue > zbir){
                console.log(zbir, inputValue);
                $('#vecaKolicina').modal('show');
                check=false;
                return;
            }
        });

        $('form').submit(function(){

            if(check == false){
                return false;
            }
        });
        
      });

      
      $('#accordion').collapse({
        toggle: false
      })